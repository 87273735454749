import { Injectable } from '@angular/core';

import { Message } from 'primeng/api';

import { Subject } from 'rxjs';

type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notificationChange$: Subject<Message> = new Subject<Message>();

  notify(severity: Severities, summary: string, detail: string): void {
    this.notificationChange$.next({ severity, summary, detail, life: 2500 });
  }

  add(message: Message): void {
    this.notificationChange$.next(message);
  }
}
